import { ReactElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { SlatePlugin } from '@udecode/slate-plugins-core';
import { Node as SlateNode, Text as SlateText } from 'slate';
import { RenderElementProps, RenderLeafProps } from 'slate-react';

// Remove extra whitespace generated by ReactDOMServer
const trimWhitespace = (rawHtml: string): string =>
  rawHtml.replace(/(\r\n|\n|\r|\t)/gm, '');

// Remove redundant data attributes
const stripSlateDataAttributes = (rawHtml: string): string =>
  rawHtml
    .replace(/( data-slate)(-node|-type)="[^"]+"/gm, '')
    .replace(/( data-testid)="[^"]+"/gm, '');

/**
 * Remove all class names that are not starting with `slate-`
 */
const stripClassNames = (html: string) => {
  const allClasses = html.split(/(class="[^"]*")/g);

  let filteredHtml = '';
  allClasses.forEach((item, index) => {
    if (index % 2 === 0) {
      return (filteredHtml += item);
    }
    const slateClassNames = item.match(/(slate-[^"\s]*)/g);
    if (slateClassNames) {
      filteredHtml += `class="${slateClassNames.join(' ')}"`;
    }
  });

  return filteredHtml;
};

const getNode = (elementProps: RenderElementProps, plugins: SlatePlugin[]) => {
  // If no type provided we wrap children with div tag
  if (!elementProps.element.type) {
    return `<div>${elementProps.children}</div>`;
  }

  let html: string | undefined;

  // Search for matching plugin based on element type
  plugins.some((plugin) => {
    if (!plugin.renderElement) return false;
    if (
      !plugin.deserialize?.element?.some(
        (item) => item.type === String(elementProps.element.type)
      )
    ) {
      html = `<div>${elementProps.children}</div>`;
      return false;
    }

    // Render element using picked plugins renderElement function and ReactDOM
    html = renderToStaticMarkup(
      plugin.renderElement(elementProps) as ReactElement
    );

    html = stripClassNames(html);

    return true;
  });

  return html;
};

const getLeaf = (leafProps: RenderLeafProps, plugins: SlatePlugin[]) => {
  const { children } = leafProps;
  return plugins.reduce((result, plugin) => {
    if (!plugin.renderLeaf) return result;
    if (plugin.renderLeaf(leafProps) === children) return result;

    const newLeafProps = {
      ...leafProps,
      children: encodeURIComponent(result),
    };

    let html = decodeURIComponent(
      renderToStaticMarkup(plugin.renderLeaf(newLeafProps))
    );

    html = stripClassNames(html);

    return html;
  }, children);
};

/**
 *
 * @param plugins
 */
export const serializeHTMLFromNodes = (plugins: SlatePlugin[]) => (
  nodes: SlateNode[]
): string => {
  const result = nodes
    .map((node: SlateNode) => {
      if (SlateText.isText(node)) {
        return getLeaf(
          {
            leaf: node as SlateText,
            text: node as SlateText,
            children: node.text,
            attributes: { 'data-slate-leaf': true },
          },
          plugins
        );
      }
      return getNode(
        {
          element: node,
          children: encodeURIComponent(
            serializeHTMLFromNodes(plugins)(node.children)
          ),
          attributes: { 'data-slate-node': 'element', ref: null },
        },
        plugins
      );
    })
    .join('');
  return stripSlateDataAttributes(trimWhitespace(decodeURIComponent(result)));
};
